import React from 'react';
import "../styles/Footer.css"

export default function Footer() {

    return (
        <div className="footer-bg">
            {/* <h1 className="title">CUNY HACKATHON</h1> */}
            <h2 className="made-by">Made with ❤ by the CUNY Hackathon Team</h2>
        </div>
    )

}